import React from 'react';
import { camelCase } from 'lodash';
import { emdash } from 'components/common';
import moment from 'moment';
import { getYesOrNo } from 'components/vehicle-details/sidenav-widgets/vehicle-overview/helpers/common';

import {
  pmSchedulesTemplate,
  assignmentTemplate,
  dataChangeTemplate,
  statusChangeTemplate,
  terminationTemplate,
  wexCardReplacementTemplate,
} from './activity-templates';

export const formatDateRangeFilter = (filters) => {
  return filters.map((filter) => {
    if (filter.key === 'transactionDate') {
      const beginning = filter.value.beginning
        ? moment(filter.value.beginning)
            .format('YYYY-MM-DD')
            .concat('T00:00:00.000Z')
        : filter.value.beginning;
      const end = filter.value.end
        ? moment(filter.value.end).format('YYYY-MM-DD').concat('T23:59:59.999Z')
        : filter.value.end;
      if (!beginning || !end) return 'error';
      return {
        key: filter.key,
        operator: filter.operator,
        value: [beginning, end],
      };
    }
    return filter;
  });
};

const displayNameByTypeMapping = {
  statusChange: 'Status',
  plateAssociation: 'License plate',
  assignmentChange: 'Customer',
  pmScheduleChange: 'Inspection schedule',
  termination: 'Customer',
  fleetCardReplacement: 'Status and shipping address',
  // replacement: 'Replacement',
  // modifications: 'Modifications',
};

const generateTemplateByType = (data, type) => {
  switch (type) {
    case 'statusChange':
      return statusChangeTemplate(data);
    case 'plateAssociation':
      return <div>{data?.[0]?.tagNumber || emdash}</div>;
    case 'dataChange':
    case 'rateChange':
      return dataChangeTemplate(data);
    case 'assignmentChange':
      return assignmentTemplate(data);
    case 'pmScheduleChange':
      return pmSchedulesTemplate(data);
    case 'termination':
      return terminationTemplate(data);
    case 'fleetCardReplacement':
      return wexCardReplacementTemplate(data);
    default:
      return data?.length > 0 ? data.join(' ') : emdash;
  }
};

const formatActivityBodyByType = (previousData, updatedData, type) => {
  if ((!previousData && !updatedData) || !type) return null;
  const combinedDataByType = {
    key: type,
    updatedData,
    previousData,
    displayName: displayNameByTypeMapping[type] || type,
  };
  return (
    <>
      {(combinedDataByType?.previousData?.length > 0 ||
        combinedDataByType?.updatedData?.length > 0) && (
        <div className="grid-row grid-gap margin-top-2">
          <div className="grid-col-4" />
          <div className="grid-col-4">
            <div>
              <strong>New:</strong>
            </div>
          </div>
          <div className="grid-col-4">
            <div>
              <strong>Previous:</strong>
            </div>
          </div>
        </div>
      )}
      <div className="grid-row grid-gap padding-y-1">
        {type === 'dataChange' ||
        type === 'rateChange' ||
        type === 'fleetCardReplacement' ? (
          <>{generateTemplateByType(combinedDataByType, type)}</>
        ) : (
          <>
            <div className="grid-col-4">
              <strong>{combinedDataByType.displayName}</strong>
            </div>
            <div className="grid-col-4">
              {generateTemplateByType(combinedDataByType.updatedData, type)}
            </div>
            <div className="grid-col-4">
              {generateTemplateByType(combinedDataByType.previousData, type)}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const formatActivities = (activities) => {
  return activities?.map((activity) => {
    return {
      id: activity?.assetTransactionHistoryId,
      type: activity?.transactionHistoryCategoryTypeName?.description,
      date: activity?.transactionDate,
      userName: activity?.createdByUser?.fullName || activity?.createdBy,
      includeUserNameInHeading: false,
      comment: activity?.comment,
      activityBody: formatActivityBodyByType(
        activity?.previousDataDisplay,
        activity?.updatedDataDisplay,
        camelCase(activity?.transactionHistoryCategoryTypeName?.description),
      ),
    };
  });
};

export const createNewFilteredHistoryRecords = (filteredHistoryRecords) => {
  if (filteredHistoryRecords) {
    return filteredHistoryRecords.map((x) => {
      if (x.transactionHistoryCategoryTypeId === 5) {
        const newPreviousDataDisplay = x.previousDataDisplay.map((data) => {
          return {
            ...data,
            manualEntryRate: getYesOrNo(data?.manualEntryRate),
          };
        });

        const newUpdatedDataDisplay = x.updatedDataDisplay.map((data) => {
          return {
            ...data,
            manualEntryRate: getYesOrNo(data?.manualEntryRate),
          };
        });

        return {
          ...x,
          previousDataDisplay: newPreviousDataDisplay,
          updatedDataDisplay: newUpdatedDataDisplay,
        };
      }

      return x;
    });
  }
  return [];
};
