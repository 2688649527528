/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isPoBoxAddress } from 'utilities/format';
import * as yup from 'yup';
import { REPLACEMENT_CARD_FORM_FIELDS } from './consts';
import { useFcReplacement } from './fc-replacement-context-provider';

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
const alphanumericRegExp = /^$|^[a-z0-9 ]+$/i;

const generateReplacementCardFormSchema = (
  vehicle,
  selectedAddressOption,
  selectedContactOption,
) =>
  yup.object().shape({
    [REPLACEMENT_CARD_FORM_FIELDS['tagNumber'].name]: yup
      .string()
      .required()
      .label('Plate number')
      .matches(alphanumericRegExp, 'Should be alpha-numeric.'),
    [REPLACEMENT_CARD_FORM_FIELDS['reason'].name]: yup
      .string()
      .when([], {
        is: () => vehicle,
        then: yup.string().required(),
      })
      .label('Reason for replacement'),
    [REPLACEMENT_CARD_FORM_FIELDS['comment'].name]: yup
      .string()
      .when('reason', (reason, schema) => {
        return (reason === 'Stolen' || reason === 'Lost') && vehicle
          ? schema.required('Comments are required for lost and stolen cards.')
          : schema;
      })
      .label('Comment')
      .max(100, 'Must be 100 characters or less.'),
    [REPLACEMENT_CARD_FORM_FIELDS['agency'].name]: yup
      .string()
      .when([], {
        is: () => selectedAddressOption === 'new' && vehicle,
        then: yup.string().required(),
      })
      .label('Agency'),
    [REPLACEMENT_CARD_FORM_FIELDS['address1'].name]: yup
      .string()
      .when([], {
        is: () => selectedAddressOption === 'new' && vehicle,
        then: yup
          .string()
          .required()
          .test('PO BOX', 'Do not enter a PO Box', (address1) => {
            return !isPoBoxAddress(address1);
          }),
      })
      .label('Address line 1'),
    [REPLACEMENT_CARD_FORM_FIELDS['address2'].name]: yup
      .string()
      .when([], {
        is: () => selectedAddressOption === 'new' && vehicle,
        then: yup
          .string()
          .test('PO BOX', 'Do not enter a PO Box', (address2) => {
            return !isPoBoxAddress(address2);
          })
          .nullable(),
      })
      .label('Address line 2')
      .max(40, 'Must be 40 characters or less.')
      .nullable(),
    [REPLACEMENT_CARD_FORM_FIELDS['city'].name]: yup
      .string()
      .when([], {
        is: () => selectedAddressOption === 'new' && vehicle,
        then: yup.string().required(),
      })
      .label('City'),
    [REPLACEMENT_CARD_FORM_FIELDS['state'].name]: yup
      .string()
      .when([], {
        is: () => selectedAddressOption === 'new' && vehicle,
        then: yup
          .string()
          .matches(/^[A-Z]{2}$/, 'State is a required field')
          .label('State'),
      })
      .label('State'),
    [REPLACEMENT_CARD_FORM_FIELDS['postalCode'].name]: yup
      .string()
      .when([], {
        is: () => selectedAddressOption === 'new' && vehicle,
        then: yup
          .string()
          .required()
          .matches(zipRegExp, 'ZIP code is not valid'),
      })
      .label('ZIP code'),
    [REPLACEMENT_CARD_FORM_FIELDS['firstName'].name]: yup
      .string()
      .when([], {
        is: () => selectedContactOption === 'new' && vehicle,
        then: yup.string().required(),
      })
      .label('First name'),
    [REPLACEMENT_CARD_FORM_FIELDS['lastName'].name]: yup
      .string()
      .when([], {
        is: () => selectedContactOption === 'new' && vehicle,
        then: yup.string().required(),
      })
      .label('Last name'),
    [REPLACEMENT_CARD_FORM_FIELDS['phoneNumber'].name]: yup
      .string()
      .when([], {
        is: () => selectedContactOption === 'new' && vehicle,
        then: yup
          .string()
          .required()
          .matches(phoneRegExp, 'Phone number is not valid')
          .test(
            'Phone number is not valid',
            'Phone number is not valid',
            (phoneNumber) => {
              if (
                phoneNumber === '0000000000' ||
                phoneNumber === '9999999999'
              ) {
                return false;
              }
              return true;
            },
          ),
      })
      .label('Phone number'),
    [REPLACEMENT_CARD_FORM_FIELDS['extension'].name]: yup.string().when([], {
      is: () => selectedContactOption === 'new' && vehicle,
      then: yup
        .string()

        .max(6, 'Extension cannot be longer than 6 characters'),
    }),
    [REPLACEMENT_CARD_FORM_FIELDS['email'].name]: yup
      .string()
      .when([], {
        is: () => selectedContactOption === 'new' && vehicle,
        then: yup.string().required().email(),
      })
      .label('Email'),
  });

const ReplacementCardFormContext = ({ children }) => {
  const { vehicle, selectedAddressOption, selectedContactOption, orderData } =
    useFcReplacement();
  const inputData = {
    [REPLACEMENT_CARD_FORM_FIELDS['tagNumber'].name]:
      orderData?.tagNumber || '',
    [REPLACEMENT_CARD_FORM_FIELDS['reason'].name]: orderData?.reason || '',
    [REPLACEMENT_CARD_FORM_FIELDS['comment'].name]: orderData?.comment || '',
  };

  const methods = useForm({
    resolver: yupResolver(
      generateReplacementCardFormSchema(
        vehicle,
        selectedAddressOption,
        selectedContactOption,
      ),
    ),
    defaultValues: inputData,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

ReplacementCardFormContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReplacementCardFormContext;
