import { orderBy } from 'lodash';
import moment from 'moment';
import { LIFECYCLE_UPDATE_TYPE } from 'utilities/consts';
import { MILEAGE_HISTORY_STATUS } from './consts';

export const getLastReportedMileage = (
  mileageHistory,
  excludeEstimated = false,
) => {
  let mileageHistoryList =
    mileageHistory?.getMileageByVehicle?.rows ||
    mileageHistory?.getMileageByGFVehicle?.rows;
  mileageHistoryList = mileageHistoryList?.filter(
    (mileage) => mileage?.status !== 'Pending',
  ); // exclude pending ones
  if (excludeEstimated) {
    mileageHistoryList = mileageHistoryList?.filter(
      (mileage) => mileage?.sourceType !== 'Estimated',
    ); // exclude Estimated ones
  }
  return orderBy(mileageHistoryList, 'mileageDate', 'desc')?.[0];
};

export const isReportedOrPendingMileage = (mileageReportingStatus) => {
  return (
    mileageReportingStatus === MILEAGE_HISTORY_STATUS.Reported ||
    mileageReportingStatus === MILEAGE_HISTORY_STATUS.Pending ||
    mileageReportingStatus === MILEAGE_HISTORY_STATUS.Telematics
  );
};

export const isMileageBetweenAllowedThreshold = (
  allowedThreshold,
  mileageHistory,
  enteredMileage,
) => {
  const lastMileageHistoryRecord = getLastReportedMileage(mileageHistory);
  const lastOdometer = lastMileageHistoryRecord?.odometer || 0;
  const mileage = enteredMileage ? Number(enteredMileage) : 0;
  return !enteredMileage || Math.abs(mileage - lastOdometer) < allowedThreshold;
};

export const isMileageReportedForThisMonth = (
  lastAssetMileageReportingStatusRecord,
) => {
  if (
    !lastAssetMileageReportingStatusRecord ||
    !lastAssetMileageReportingStatusRecord?.length
  ) {
    return false;
  }
  const record = lastAssetMileageReportingStatusRecord?.[0];
  const lastAssetMileageReportingStatusRecordDate = moment(
    `${record?.mileageReportingMonth}-01-${record?.mileageReportingYear}`,
  );
  return (
    moment(lastAssetMileageReportingStatusRecordDate).isSame(
      moment(),
      'month',
    ) && isReportedOrPendingMileage(record?.mileageReportingStatus)
  );
};

export const getLifecycleIndicatorStatus = (vehicle) => {
  if (vehicle?.assetLifecycle && vehicle?.assetLifecycle.length > 0) {
    return vehicle?.assetLifecycle[0].lifeCycle.lifecycleIndicatorId;
  }
  return null;
};

export const skipTelematicsActiveCheck = (vehicle, isRole) => {
  return (
    vehicle?.ownershipTypeCode === 'GF' &&
    (isRole('FMCManager') || isRole('FMVRSAdminRole') || isRole('SiteAdmin')) &&
    getLifecycleIndicatorStatus(vehicle) ===
      LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_UNASSIGNED.toString()
  );
};

export const disableButtonByRoleAndStatus = (
  vehicle,
  isRole,
  isGsaEmployee,
) => {
  if (isGsaEmployee() && vehicle?.ownershipTypeCode === 'GF') {
    if (
      // FSR or fleet base permission
      !(
        isRole('FMCManager') ||
        isRole('FMVRSAdminRole') ||
        isRole('SiteAdmin')
      ) &&
      getLifecycleIndicatorStatus(vehicle) ===
        LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_UNASSIGNED.toString()
    ) {
      return true;
    }

    if (
      // FMC Manager, FSR or fleet base permission
      !(isRole('FMVRSAdminRole') || isRole('SiteAdmin')) &&
      (getLifecycleIndicatorStatus(vehicle) ===
        LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_READY_FOR_SALE.toString() ||
        getLifecycleIndicatorStatus(vehicle) ===
          LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_LOTTED_FORSALE.toString())
    ) {
      return true;
    }
  }

  return false;
};

export const disableAddNewMileageRecord = ({
  vehicle,
  isTelematicsActive,
  TELEMATICS_JOB_RUN_DAY,
  isRole,
  isGsaEmployee,
}) => {
  if (skipTelematicsActiveCheck(vehicle, isRole)) {
    return false;
  }

  if (disableButtonByRoleAndStatus(vehicle, isRole, isGsaEmployee)) {
    return true;
  }

  return (
    vehicle?.ownershipTypeCode === 'GF' &&
    isTelematicsActive &&
    new Date().getDate() < TELEMATICS_JOB_RUN_DAY
  );
};
