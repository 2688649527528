/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { AFPTable } from '@gsa/afp-component-library';
import EditableCell from 'utilities/editable-cell';

const tableRef = React.createRef();

const initialOrderState = 'class ASC';

const CreateOrdersTable = ({ ordersList, updateCellData, tableIndex }) => {
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Class',
        accessor: 'classLabel',
        sortable: false,
        Cell: ({ value }) => {
          return value;
        },
      },

      {
        Header: 'Quantity',
        accessor: 'quantity',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row, column, data }) => {
          return (
            <>
              <EditableCell
                key={`${value}-day-in-use`}
                inputType="number"
                row={row}
                data={data}
                column={column}
                defaultValue={value}
                updateCellData={updateCellData}
                aria-label={`${column?.id} editable field`}
                allowNegative={false}
              />
            </>
          );
        },
      },
    ];

    return columnList;
  }, []);

  return (
    <>
      <AFPTable
        ref={tableRef}
        defaultSort={initialOrderState}
        testId={`create-orders-table-${tableIndex}`}
        columns={columns}
        data={ordersList ?? []}
        updateCellData={updateCellData}
        skipPageReset
      />
    </>
  );
};

export default CreateOrdersTable;
