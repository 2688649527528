/* eslint-disable react/prop-types */
import React from 'react';
import { emdash } from 'components/common';

const DetailRow = ({ label, value, className, email }) => {
  return (
    <div className="expanded-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-primary-lighter">
      <div className="expanded-title">
        <strong>{label}</strong>
      </div>
      {label === 'User' && value ? (
        <div className={`expanded-value ${className}`}>
          <Mailto email={email}>{value}</Mailto>
        </div>
      ) : (
        <div className={`expanded-value ${className}`}>{value || emdash}</div>
      )}
    </div>
  );
};

const Mailto = ({ email, subject, body, ...props }) => {
  return (
    <a
      aria-label="mailto"
      href={`mailto:${email}?subject=${subject || ''}&body=${body || ''}`}
    >
      {props.children}
    </a>
  );
};

const getSourceType = (methodOfEntry) => {
  if (methodOfEntry === 'GORP') {
    return 'GORP/FCT';
  }
  if (methodOfEntry === 'DESC') {
    return 'GORP/DESC';
  }
  return methodOfEntry;
};

const getEnteredBy = (methodOfEntry, user) => {
  if (methodOfEntry === 'GORP' || methodOfEntry === 'DESC') {
    return null;
  }
  if (user?.toUpperCase().includes('VMS')) return emdash;
  return user;
};

const MileageHistoryRow = ({
  methodOfEntry,
  user,
  dateOfEntry,
  email,
  customer,
}) => {
  return (
    <div className="grid-row grid-gap grid-col-12 padding-left-2 ">
      <div className="tablet:grid-col-6">
        {customer && (
          <DetailRow
            label="Leasing account name"
            value={customer?.accountName}
          />
        )}
        {customer && <DetailRow label="BOAC" value={customer?.boac} />}
        {customer && (
          <DetailRow
            label="Legacy customer number"
            value={customer?.legacyCustomerNumber}
          />
        )}
      </div>
      <div className="tablet:grid-col-6">
        <DetailRow
          label="User"
          value={getEnteredBy(methodOfEntry, user)}
          email={email}
        />
        <DetailRow
          label="Method of entry"
          value={getSourceType(methodOfEntry)}
        />
        <DetailRow label="Date of entry" value={dateOfEntry} />
      </div>
    </div>
  );
};

export { MileageHistoryRow };
