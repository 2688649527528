/* eslint-disable prefer-destructuring */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import { FETCH_TYPEAHEAD } from '../../../services/data-layer';

import { getFilterWithCustomerNumber, processUpdate } from './utils';
import { vehicleFilterKeys } from '../../user-filters/filter-structures/constants';
import {
  actions,
  useVehicleInventoryFilter,
} from '../vehicle-inventory-filter-provider';

const SidebarWidget = ({ onChange, order, updateAppliedFilters }) => {
  const {
    filterStructure,
    setAssembledFilters,
    dispatchAction,
    getFilteredVendors,
    typeaheadData,
  } = useVehicleInventoryFilter();

  const [fetchTypeaheads, { data }] = useLazyQuery(FETCH_TYPEAHEAD, {
    fetchPolicy: 'network-only',
  });

  const handleFilterChange = ({ allUpdates }) => {
    processUpdate({ allUpdates, filterStructure, setAssembledFilters });
  };

  useEffect(() => {
    if (data) {
      dispatchAction(actions.SET_TYPEAHEAD_DATA, data.getTypeaheads.typeaheads);
    }
  }, [data]);

  // To be called when the filter is expanded

  const history = useHistory();

  const handleClearAll = () => {
    const unpdatedAssembledFilter = getFilterWithCustomerNumber(
      filterStructure,
      undefined,
      'delete',
    );

    dispatchAction(actions.SET_CUSTOMER_NUMBER, undefined);
    setAssembledFilters(unpdatedAssembledFilter);
    history.push({
      pathname: '/vehicles',
      state: {},
    });
  };

  const handleFetchTypeaheads = (filters) => {
    if (filters.variables.field === vehicleFilterKeys.VEHICLE_LOCATION) {
      const search = filters.variables?.filters[0]?.conditions.find(
        ({ key, operator }) =>
          key === vehicleFilterKeys.VEHICLE_LOCATION &&
          operator === '$startsWith',
      )?.value;
      getFilteredVendors({
        variables: {
          key: 'id',
          search,
          vendorType: 'MAR',
        },
      });
    } else {
      fetchTypeaheads({ ...filters });
    }
  };

  if (filterStructure.length > 0)
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={(params) => {
          handleFilterChange({ allUpdates: params });
          onChange(params);
        }}
        clearButtonLabel="Reset all"
        showClearIcon
        model="Vehicle"
        order={order || [['id', 'ASC']]}
        fetchTypeaheads={handleFetchTypeaheads}
        typeaheadData={typeaheadData}
        updateAppliedFilters={updateAppliedFilters}
        handleClearAll={handleClearAll}
      />
    );

  return <></>;
};

SidebarWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  order: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,

  updateAppliedFilters: PropTypes.func.isRequired,
};

export default SidebarWidget;
